import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import debounce from 'lodash/debounce';

export default class extends Controller {
    static targets = [
        'stepsTabs',
        'stepsContents',
        'selectedDateFidelityPoints',
    ];

    connect() {
        this.init();
    }

    /**
     * Select the day to display and hide to others
     * @param event
     */
    handleSelectStep(event) {
        const stepIndex = event.params?.index;

        // hit pagination limit, do nothing
        if (stepIndex < 0 || stepIndex === this.stepsTabsTargets.length) {
            return;
        }
        this.stepsTabsTargets.forEach((tab) => {
            tab.classList.remove(
                'border-b-4',
                'border-blue-brand',
                'border-solid',
                'text-content-brand',
                'font-semibold',
            );
        });
        this.stepsTabsTargets[stepIndex].classList.add(
            'border-b-4',
            'border-blue-brand',
            'border-solid',
            'text-content-brand',
            'font-semibold',
        );
        this.stepsTabsTargets[stepIndex].scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
        });

        this.stepsContentsTargets.forEach((s) => {
            s.classList.add('hidden');
        });
        this.stepsContentsTargets[stepIndex].classList.remove('hidden');
    }

    // ****** Old code to be refactored  ********
    // ****************************************
    // ****************************************
    init() {
        var $asidePanel = $('#aside-panel');

        $('#bookbutton', $asidePanel).on('click', function (event) {
            var error = false;
            $('.next-dates-error').hide();
            $('.children-age-error').hide();
            $('.children-number-error').hide();

            // loading spinner
            const $buttonSpinner = $('.spinner', '#bookbutton');
            const $buttonSpanText = $('span', '#bookbutton');
            if ($buttonSpinner[0]) {
                $buttonSpinner[0].classList.add('inline-block');
                $buttonSpinner[0].classList.remove('hidden');
            }
            if ($buttonSpanText[0]) $buttonSpanText[0].classList.add('hidden');
            var isDesktop = window.innerWidth >= 1300;

            if ($('#next-dates').val() === '') {
                error = true;

                if (isDesktop) {
                    $('.next-dates-error').css('display', 'flex');
                }

                if ($('#travel-tour-dates').length > 0) {
                    $('html,body').animate(
                        {
                            scrollTop: $('#travel-tour-dates').offset().top,
                        },
                        1000,
                    );
                }
            }

            if ($('#nbChildren').val() === '') {
                error = true;
                if (isDesktop) {
                    $('.children-number-error').css('display', 'flex');
                }
            }

            Array.from(
                document.querySelectorAll('select[name="childrenAge[]"]'),
            ).map((select) => {
                if (select.value == null || select.value === '') {
                    if (isDesktop) {
                        $('.children-age-error').css('display', 'flex');
                    }
                    error = true;
                }
            });

            if (error) {
                // remove loading spinner
                if ($buttonSpinner[0]) {
                    $buttonSpinner[0].classList.remove('inline-block');
                    $buttonSpinner[0].classList.add('hidden');
                }
                if ($buttonSpanText[0])
                    $buttonSpanText[0].classList.remove('hidden');
                event.preventDefault();
                return false;
            }
        });

        $('#form-book-dates', $asidePanel).on('submit', () => {
            const bookButton = $('#bookbutton');
            if (bookButton[0]) bookButton[0].disabled = true;
        });

        // Synchronize dates table and dates select.
        var hasShowMoreButton = '{{ @datesCount.total>@datesCount.visible }}';
        var totalDatesVisible = '{{ @datesCount.visible }}';

        $('input[name=startDateTable]').on('click', function (event) {
            event.stopPropagation();
        });

        // ok
        $(document).on('change', 'input[name=startDateTable]', (event) => {
            var selectedDate = $(event.target).val();
            $('select[name=startDate]').val(selectedDate);
            this.updateAside(selectedDate);
        });

        // ok
        $asidePanel.on('change', 'select[name=startDate]', () => {
            var $startDateSelect = $('select[name=startDate]');
            var selectedDate = $startDateSelect.val();
            var selectedDateIndex = $startDateSelect.prop('selectedIndex');

            this.updateAside(selectedDate);
            $('input[name=startDateTable]:checked').prop('checked', false);

            if (selectedDate) {
                $(
                    'input[name=startDateTable][value = ' + selectedDate + ']',
                ).prop('checked', true);
            }

            // If the selected date is not shown, display the rest of dates inside the table.
            if (hasShowMoreButton && selectedDateIndex > totalDatesVisible) {
                $('#action-showmore-dates').click();
            }
        });

        /// scroll debounce
        $(window).on(
            'scroll',
            debounce(this.activateCurrentNavigationLink, 100),
        );
        this.activateCurrentNavigationLink();

        // smooth scroll
        var $links = $('#travel-tour-links'),
            $nav = $('.travel-tour-links-nav', $links),
            $navLinks = $('> .link', $nav);

        var $headerLink = $('#header-link');

        $navLinks.on('click', this.smoothScroll);
        if ($headerLink) {
            $headerLink.on('click', this.smoothScroll);
        }

        // data row click event
        $('[data-travel-tour-dates-datarow]').on('click', function () {
            $(this).find('input[type=radio]').click();
        });
    }

    smoothScroll(event) {
        var $links = $('#travel-tour-links');
        const $nav = $('.travel-tour-links-nav', $links);
        var $link = $(this),
            $target = $($link.attr('href'));

        const scrollThreshold = Math.max(
            0,
            Math.floor($target.offset().top - $nav.height() - 10),
        );
        event.preventDefault();

        $('html, body').animate(
            {
                scrollTop: scrollThreshold,
            },
            750,
        );
    }

    activateCurrentNavigationLink() {
        var $links = $('#travel-tour-links'),
            $nav = $('.travel-tour-links-nav', $links),
            $navLinks = $('> .link', $nav);

        var $latestActiveLink;

        $navLinks
            .each((i, e) => {
                var $link = $(e),
                    $target = $($link.attr('href'));
                const scrollThreshold = Math.max(
                    0,
                    Math.floor($target.offset().top - $nav.height() - 10),
                );
                if ($target.length > 0 && window.scrollY >= scrollThreshold) {
                    $latestActiveLink = $link;
                    //  mobile scroll current active link into viewport
                    // if ($link[0]) $link[0].scrollIntoView();
                }
            })
            .removeClass('after:w-full')
            .removeClass('text-content-brand');
        $latestActiveLink &&
            $latestActiveLink.addClass('after:w-full') &&
            $latestActiveLink.addClass('text-content-brand');
    }

    updateAside(selectedDate) {
        var $description = $('.travelers__description');
        var $status = $('.travelers__status');
        var $tooltip = $('.travelers__tooltip');

        var $actualprice = $('[data-aside-panel-actualprice-target]');
        var $minprice = $('[data-aside-panel-minprice-target]');
        var $rebatePercentage = $('[data-aside-panel-rebatepercentage-target]');
        var $fromLabel = $('[data-aside-panel-fromlabel-target]');

        if (!selectedDate) {
            $description.addClass('travelers__description--none');
            $minprice.removeClass('is-hidden minprice--small');
            $status.text('');
            $tooltip.text('');
            return;
        }

        // Update dates
        $minprice.addClass('minprice--small');

        // Update status
        var $activeStatus = $(
            '.travel-tour-dates__status[data-date=' + selectedDate + ']',
        );

        var tooltip = $activeStatus.data('tooltip');
        var status = $activeStatus.data('status');

        $description.removeClass('travelers__description--none');
        $status.text(status);
        $tooltip.text(tooltip);

        // fetch selected date element
        var $activePrice = $(
            '.travel-tour-dates__price[data-date=' + selectedDate + ']',
        );
        // retrieve all prices
        var formattedPrice = $activePrice.data('formattedprice');
        var formattedRebatedPrice = $activePrice.data('formattedrebatedprice');
        var rebatePercentage = $activePrice.data('rebatepercentage');
        $fromLabel.addClass('hidden');

        const price = $activePrice.data('price');
        const rebatedPrice = $activePrice.data('rebatedprice');
        let fidelityPoints = null;

        // display discounts
        if (formattedRebatedPrice) {
            $minprice.removeClass('hidden');
            $rebatePercentage.removeClass('hidden');
            $minprice.html(formattedPrice);
            $rebatePercentage.html(rebatePercentage + '%');
            $actualprice.html(formattedRebatedPrice);
            // update fidelity points
            fidelityPoints = Math.floor(Number(rebatedPrice)) * 10;
        } else {
            // default case, normal price
            $minprice.addClass('hidden');
            $rebatePercentage.addClass('hidden');
            $actualprice.html(formattedPrice);
            // update fidelity points
            fidelityPoints = Math.floor(Number(price)) * 10;
        }
        // update fidelity points labels
        if (this.hasSelectedDateFidelityPointsTarget) {
            this.selectedDateFidelityPointsTargets.forEach((element) => {
                element.innerHTML = fidelityPoints
                    .toLocaleString()
                    .replace(',', ' ');
            });
        }

        $('.next-dates-error').hide();

        // Update max travellers
        var $nbAdults = $('input[name=nbAdults]');
        var $nbChildren = $('input[name=nbChildren]');
        var remainingStock = document.querySelector(
            'select[name=startDate] option[value="' + selectedDate + '"]',
        ).dataset.remainingStock;
        if (remainingStock !== null) {
            $nbAdults.attr('max', remainingStock);
            $nbAdults.attr('maxStock', remainingStock);
            $nbAdults.trigger('change-max', remainingStock);
        }
        // change date: reset input state
        $nbAdults.val($nbAdults.attr('min')).trigger('input');
        $nbChildren.val('0').trigger('input');
        if ($nbAdults[0]) {
            $nbAdults[0].dispatchEvent(new Event('input', { bubbles: true }));
            $nbAdults[0].dispatchEvent(new Event('reset'));
        }
        if ($nbChildren[0]) {
            $nbChildren[0].dispatchEvent(new Event('input', { bubbles: true }));
            $nbChildren[0].dispatchEvent(new Event('reset'));
        }
    }
}
